@import "../../style.scss";

@keyframes glow {
    0%,
    100% {
    box-shadow: 0 0 6px rgba(52, 152, 219, 0.7);
    }
    80% {
    box-shadow: 0 0 6px rgba(52, 152, 219, 0.9), 0 0 6px rgba(52, 152, 219, 0.6);
    }
}

.board {
    @include themify($themes) {
        width: 100%;
        height: calc(100vh - 70px - 51px);
        background-color: themed("bgSoft");
        max-width: 100vw;

        .board-live {
            justify-content: center;
            display: flex;
            padding-top: 50px;
            margin-bottom: 30px;
            background-color: themed("bg");
            max-width: 100vw;

            .screenTextDiv {
                
                .screenText{
                    background-color: black;
                    p {color: white;}
                    div {background-color: white;}
                }
            }


            .cards-container {
                position: absolute;
                background-color: transparent;
                width: 140px;
                height: 50px;
                display: flex;
                justify-content: center;
                gap: 12px;
                z-index: 10;
                pointer-events: none;

                .playercard {
                    background-color: rgb(189, 189, 189);
                    border: 1.7px solid black;
                    width: 30px;
                    height: 50px;
                    border-radius: 5px;
                    z-index: 11;
                    display: flex;
                    object-fit: cover;

                    img {
                        width: 100%;
                        height: 100%;
                        // object-fit: cover;
                        border-radius: 5px; 
                    }
                }

                .coins {
                    margin-top: 12px;
                    right: 0;
                    position: absolute;
                    background-color: transparent;
                    border: 1px solid black;
                    width: 25px;
                    height: 25px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    color: black;
                    font-size: 17px;
                    z-index: 11;

                    .coins2 {
                        border: 1px solid black;
                        width: 21px;
                        height: 21px;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        color: black;
                        font-size: 17px;
                    }
                }
            }
        }


        .take-coins {
            position: absolute;
            height: 100px;
            top: 100px;
            right: calc(50vw - 50px - 220px);
            user-select: none;


            .take {
                display: flex;
                align-items: center;
                justify-content: center;
                color: themed("logo");
                border: 1px solid themed("logo");
                border-radius: 10px;
                margin-bottom: 15px;
                padding: 10px 12px;
                cursor: pointer;
                user-select: none;
                &:hover {
                    opacity: 0.7;
                }
            }

          
            
        }

        .cards {
            width: 100%;
            height: 50px;
            display: flex;
            justify-content: center;
            gap: 20px;

            .card {
                border-top: 1px solid themed("border");
                border-right: 1px solid themed("border");
                border-left: 1px solid themed("border");
                border-top-left-radius: 10px;
                border-top-right-radius: 10px;
                width: 190px;
                height: 50px;
                background-color: themed("bg");
                box-shadow: 0 0 6px rgba(52, 152, 219, 0.9), 0 0 6px rgba(52, 152, 219, 0.6);
                user-select: none;

                p {
                    color: themed("logo");
                    padding-left: 10px;
                    padding-top: 8px;
                    font-size: 20px;
                }
            }

            .coins {
                width: 44px;
                height: 44px;
                color: themed("logo");
                font-size: 23px;
                border: 1px solid themed("logo");
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;
                position: absolute; 
                right: calc(50vw - 22.5px - 246px);
                margin-top: -3px;
                
                .coins2 {
                    border: 1px solid themed("logo");
                    width: 39px;
                    height: 39px;
                    border-radius: 50%;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    user-select: none;
                }
            }
        }

        .board-actions {
            margin-top: 1px;
            justify-content: center;
            display: flex;
            gap: 20px;
            border-top: 1px solid themed("border");
            padding: 20px;

            * {
                padding: 10px 25px;
                background-color: themed("bg");
                border: 1px solid themed("logo");
                cursor: pointer;
                color: themed("logo");
                font-size: 20px;
                border-radius: 8px;
                user-select: none;
                font-weight: 500;
            
                &:hover {
                    opacity: 0.7;
                }
            }
        }


    }
}