@import "../../style.scss";

.bottom {
  @include themify($themes) {
    width: 100%;
    height: 50px;
    position: fixed;
    bottom: 0;
    transition: 0.3s ease;
    z-index: 998;
    background-color: themed("bg");
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    .bottom-top {
      border-top: 1px solid themed("border");
      border-bottom: 1px solid themed("border");
      background-color: themed("logoReverse");
      width: 100%;
      height: 50px;
      justify-content: center;
      align-items: center;
      display: flex;
      border-top-left-radius: 0px;
      border-top-right-radius: 0px;
      cursor: pointer;

      .ham {
        color: themed("iconLight");
      }
    }
  }
}